.nice-dates-day.-highlight .nice-dates-day_date { 
  color:#ffffff;
  background-color: #3a6852;
  border-radius: 50%;
  transform: scale(0.95);
  /* width: 21!important;
  height: 21!important; */
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nice-dates-day.-highlight.-wide:before, .nice-dates-day.-highlight.-wide:after {
  left: 12.5%;
  right: 12.5%;
}

.glide__bullet.glide__bullet--active {
  color: #f94646;
}

.glide__bullet {
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  color: rgba(0,0,0,0.7);
}

.gmnoprint {
  display: none;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #f94646!important
} 

.MuiButton-contained.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12)!important;
}

.MuiButton-containedSecondary {
 background-color: #f94646!important;
}

.MuiButton-outlinedSecondary {
  color: #f94646!important;
  border: 1px solid #f9464650!important;
}

.MuiCircularProgress-colorSecondary {
  color: #f94646!important;
}

.MuiIconButton-colorSecondary {
  color: #f94646!important;
}

.MuiImageListItem-item {
  border-radius: 50px;
  width: 180px;
  height: 180px;
}

.MuiRadio-colorPrimary.Mui-checked {
  color: #04332f!important;
}